<template>
  <v-chart v-if="visible" ref="eChart" class="chart" :option="option" :autoresize="true" @click="toggleChart"/>
  <div v-else class="chart-nodata">
    <NoResult></NoResult>
  </div>
</template>

<script>
import {useStore} from 'vuex';
import {use} from 'echarts/core';
import {CanvasRenderer} from 'echarts/renderers';
import {PieChart} from 'echarts/charts';
import {TitleComponent, TooltipComponent, LegendComponent} from 'echarts/components';
import VChart from 'vue-echarts';
import {toRefs, computed, ref, watch} from 'vue';
import NoResult from '../../components/Error/NoResult.vue';
import helper from '@/services/helper';

use([CanvasRenderer, PieChart, TitleComponent, TooltipComponent, LegendComponent]);
/* eslint-disable */
const EChart = {
  name: 'EChart',
  components: {
    VChart,
    NoResult,
  },
  props: {
    data: Array,
    doughnutChart: Boolean,
    chartType: String,
    categoryByLevel: Object,
    level: {
      type: Number,
      default: 0,
    },
    // categoryByLevel: VueTypes.number,
  },
  setup(props, {emit}) {
    const store = useStore();
    const {data, doughnutChart, chartType, level} = toRefs(props);
    const category = computed(() => store.getters['config/categoryData']);
    const eChart = ref(null);

    const visible = computed(() => {
      // if (data.value.length) {
      //   return true;
      // }
      // return false;
      // TODO check this
      return true;
    });

    const toggleChart = (params) => {
      // console.log('chartType', chartType.value, params);
      // TODO : fix about filter
      try {
        document.querySelector('x-vue-echarts > div > div:nth-child(2)').style.display = 'none'; // hide tooltip on touch
      } catch (error) {
        console.error(error)
      }
      emit('chartClick', params);
    };

    const getLegend = () => {
      if (chartType.value === 'sentiment') {
        return {
          show: false,
          selected: {},
        };
      } else if (chartType.value === 'campaign') {
        return {
          type: 'scroll',
          show: true,
          top: 'bottom',
          icon: 'circle',
          textStyle: {
            color: '#868EAE',
            fontFamily: 'DM Sans, IBM Plex Sans Thai, serif',
            fontSize: '13px',
          },
        };
      } else if (chartType.value === 'comparison' || chartType.value === 'interest') {
        return {
          show: false,
          selected: {},
        };
      } else {
        return {
          type: 'scroll',
          textStyle: {
            color: 'rgba(134, 142, 174, 1)',
          },
          icon: 'circle',
          itemGap: 20.5,
          itemHeight: 10,
          left: 'center',
          top: 'bottom',
          data: data.value.map((item) => {
            return item.name;
          }),
        };
      }
    };

    const getRichObj = () => {
      const richObj = {
        name: {
          fontSize: 12,
          color: chartType.value === 'interest' || chartType.value === 'influencer' ? '#5A5F7D' : '#999',
          fontWeight: 400,
          lineHeight: 19,
        },
      };

      let sortedData;
      sortedData = data.value;
      for (let i in sortedData) {
        const s = sortedData[i];
        let c;
        if (chartType.value === 'interest' || chartType.value === 'influencer') {
          c = s.itemStyle.color;
        } else {
          const {name} = s;
          const n = name.toLowerCase();
          c = getColor(n, i);
          // let c = helper.getColor(n, i);
          if (c === '#333') c = '#4DD4AC';
        }
        richObj[`c${i}`] = {
          fontSize: 15,
          color: c,
          fontWeight: 700,
        };
      }
      return richObj;
    };

    const getColor = (catKey, index) => {
      let color;
      let result;
      if (category.value && category.value[level.value] && category.value[level.value].categories) {
        const categories = category.value[level.value].categories;
        result = categories.find((c) => c.visible && c.category === catKey.toLowerCase());
      }
      color = result && result.color ? result.color : null;

      if (!color) {
        color = helper.getColor(catKey, index);
      }
      return color;
    };

    const getLabel = () => {
      // if (chartType.value === 'comparison') {
      //   return {
      //     show: false,
      //     position: 'center',
      //   };
      // }
      if (
          chartType.value === 'category' ||
          chartType.value === 'sentiment' ||
          chartType.value === 'comparison' ||
          chartType.value === 'campaign' ||
          chartType.value === 'interest' ||
          chartType.value === 'influencer'
      ) {
        const richObj = getRichObj();
        return {
          show: true,
          formatter: (params) => {
            const str = `{name|${params.name}}\n{c${params.dataIndex}|${params.percent}%}`;
            return str;
          },
          rich: richObj,
        };
      }
      return {
        show: false,
      };
    };
    const getEmphasisLabel = () => {
      // if (chartType.value === 'comparison') {
      //   return {
      //     show: true,
      //     fontSize: '14',
      //     fontWeight: 'bold',
      //   };
      // }
      const richObj = getRichObj();

      return {
        show: true,
        formatter: (params) => {
          const str = `{name|${params.name}}\n{c${params.dataIndex}|${params.percent}%}`;
          return str;
        },
        rich: richObj,
      };
    };

    const getOption = () => {
      return {
        legend: getLegend(),
        tooltip: {
          trigger: 'item',
          confine: 'true',
        },
        series: [
          {
            name: chartType.value,
            type: 'pie',
            radius: doughnutChart.value
                ? ['40%', '70%']
                : chartType.value === 'campaign' || chartType.value === 'influencer'
                    ? ['0%', '50%']
                    : ['0%', '70%'],
            center:
                chartType.value === 'category'
                    ? ['50%', '42%']
                    : chartType.value === 'campaign' || chartType.value === 'influencer'
                        ? ['50%', '42%']
                        : ['50%', '50%'], // x, y
            label: getLabel(),
            // labelLine: {
            //   length: 30,
            // },
            emphasis: {
              label: getEmphasisLabel(),
            },
            data: data.value,
            startAngle: 180,
            minShowLabelAngle: chartType.value === 'interest' || chartType.value === 'influencer' ? 0 : 8,
          },
        ],
      };
    };
    let option = ref(getOption());
    watch(
        () => data.value,
        () => {
          option.value = getOption();
        }
    );

    return {option, visible, toggleChart, eChart};
  },
};

export default EChart;
</script>

<style lang="scss" scoped>
.chart-nodata {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>
